import React from "react"

import Layout from "../components/layout"
import styles from "../scss/layouts/404.module.scss"
import SEO from "../components/seo"
import cta from "../scss/elements/buttons.module.scss";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <section className={styles.page}>
      <div className={styles.page__main}>
        <h1>Page Not Found</h1>
        <p>Ooops, looks like this page no longer exists. </p>
        <div className={styles.page__button}>
          <a className={cta.button} href="/">Back To Homepage</a>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
